:root {
    --main-first: #EBCD60;
    --main-first-dark: #0c4470;
    --main-second: #fa7319;
}

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

ul {
    list-style-type: none;
}

body {
    height: 100%;
    overflow-x: hidden;
    font-family: Gilroy, sans-serif;
    font-size: 1.1em;
}

h1, h2, h3 {
    font-family: Gilroy, sans-serif;;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: var(--main-first) #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--main-first);
    border-radius: 10px;
    border: 3px solid #ffffff;
}

/* Disable animate.css on small devices */
@media only screen and (max-width: 768px) {
    .animate__animated {
        /*CSS transitions*/
        -o-transition-property: none !important;
        -moz-transition-property: none !important;
        -ms-transition-property: none !important;
        -webkit-transition-property: none !important;
        transition-property: none !important;
        /*CSS transforms*/
        -o-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -webkit-transform: none !important;
        transform: none !important;
        /*CSS animations*/
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
    }
}

.sliderArrow {
    width: 45px;
    height: 45px;
    background: none;
    border: solid 2px rgba(0, 0, 0, .5);
    border-radius: 50%;
    padding: 10px;
    margin: 10px;
    transition: .3s;
}

.sliderArrow svg {
    fill: rgba(0, 0, 0, .5);
    transition: .3s;
}

.sliderArrow:hover {
    background-color: white;
}

.sliderArrow:hover svg {
    fill: #232323;
}

/* Disable input number arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* Chat - my message overwrite */
.rce-mbox.rce-mbox-right {
    background-color: var(--main-first);
    color: white;
}
.rce-mbox.rce-mbox-right .rce-mbox-time.non-copiable:before {
    color: white;
}

.rce-mbox.rce-mbox-right .rce-mbox-right-notch {
    fill: var(--main-first);
}

/* Hide recaptcha badge */
.grecaptcha-badge { visibility: hidden; }

/* Chat */
.message-list {
    height: 100%;
    overflow-y: auto;
    padding: 0 10px;
}
