.header {
    width: 100%;
    height: 80px;
    padding: 15px 70px;
    border-bottom: 2px solid #F3F3F3;
    background: white;
}

.header > nav {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.header__logo a {
    color: var(--main, #0D4DA7);
    font-size: 40px;
    font-weight: 800;
    text-transform: uppercase;
}

.authList {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.authList a {
    display: inline-flex;
    height: 50px;
    padding: 10px 20px;
    align-items: center;
    border-radius: 5px;
    font-size: 16px;
    letter-spacing: 0.32px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    transition: .1s ease-in;
    font-family: Sofia Sans Condensed;
    background: #EBCD60;
    color: white;
    border: 1px solid #EBCD60;
}

.authList a:hover {
    background: #0e6831;
    border: 1px solid #0e6831;
}
