.layout {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.layoutGoBack {
    position: absolute;
    top: 100vh;
}

.content {
    flex: 1;
    display: flex;
    height: 100%;
}

.content > * {
    flex: 1;
}
