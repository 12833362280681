.footer {
    width: 100%;
    background-color: var(--main-first);
}

.footerWrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footerNavigation {
    flex: 3;
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-wrap: wrap;
}

.footerNavigation ul {
    padding: 15px 0;
}

.footerNavigation ul a {
    color: white;
    transition: .1s;
}

.footerNavigation ul a:hover {
    color: rgba(255, 255, 255, .8);
}

.footerSocialAndApps {
    color: white;
    flex: 2;
}

.footerSocialAndApps h4 {
    text-transform: uppercase;
    letter-spacing: 3px;
    text-align: center;
}

.footerSocialAndApps svg {
    width: 50px;
    fill: white;
    transition: .1s;
}
.footerSocialAndApps img {
    width: 50px;
    fill: white;
    transition: .1s;
}
.footerSocialAndApps svg:hover{
    transform: scale(.95);
}
.footerSocialAndApps img:hover{
    transform: scale(.95);
}

.social {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
}

.apps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.apps svg {
    width: 180px;
    height: 80px;
}

.footerCopyright {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    border-top: 1px solid #fff;
    position: relative;
    padding-top: 25px;
    text-align: center;
}

.footerCopyright a {
    color: white;
    transition: .1s;
}

.footerCopyright a:hover {
    color: rgba(255, 255, 255, .8);
}

@media (max-width: 720px) {
    .footerNavigation ul {
        text-align: center;
    }
}
