.priceInfo{
display: flex;
justify-content: flex-start;


}
.priceInfo img{
    max-width: 28px;
    max-height: 28px;
    margin-right: 5px;
    padding-left: 5px;

}